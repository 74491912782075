import React, {Component} from 'react'
import {Link} from 'gatsby';
import {connect} from 'react-redux';
import Layout from '../../components/layout';
import Confirmation from '../../images/envelope.gif';

export class RequestSentPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 0,
    }
  }
  checkScreenHeight(){
    this.setState({height: window.innerHeight - 65})
  }
  componentDidMount() {
    window.addEventListener('resize', this.checkScreenHeight.bind(this));
    this.checkScreenHeight();
  }
  
  render() {
    const containerStyle = {
      minHeight: this.state.height,
    }
    
    const {email, name, required_by} = this.props.confirmation;
    return (
      <Layout>
      <div className="w-full flex items-center -my-20 text-gray-400" style={containerStyle}>
        <div
          className="max-w-md text-center w-full mx-auto bg-white border border-gray-300 p-5 rounded-lg shadow-xl">

          <div className="confirmation-icon opacity-25">
            <img src={Confirmation} alt="" className="opacity-75"/>
          </div>
              <h3 className="text-2xl mb-4 text-blue-primary text-center">Your request has been sent successfully</h3>
          {name && email && required_by && <p className="mb-4 font-medium">
              Thank you {name}, a confirmation email has been sent to <span className="text-blue-400">{email}</span>.<br/><br/>Our team will get back in contact with you.  You will be notified when your request has been approved<br/><br/>Required by: {required_by.substring(0, 10)}
            </p>
          }
          <div className="text-center">
            <Link
              to="/"
              className="bg-blue-button inline-block mx-auto my-4 px-4 py-2 rounded text-white">Go Back to Dashboard</Link>
          </div>
        </div>
      </div>
      </Layout>
    )
  }
}
const mapStateToProps = (state) => ({confirmation: state.confirmation})

const ConnectedFormMaker = connect(mapStateToProps, null)(RequestSentPage);
export default ConnectedFormMaker;